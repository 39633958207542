.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "manrope";
  src: local("manrope"),
    url("./assets/fonts/Manrope-VariableFont_wght.ttf") format("opentype");
  font-weight: normal;
}

/*@font-face {
  font-family: "poppins";
  src: local("poppins"),
    url("./assets/fonts/Poppins-Medium.ttf") format("opentype");
  font-weight: normal;
}*/


/* styles.css */
@font-face {
  font-family: "IBM Plex Sans";
  src: url("./assets/fonts/IBMPlexSans-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("./assets/fonts/IBMPlexMono-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

.displayLarge {
  font-family: "IBM Plex Sans";
  font-size: 57px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 112.281% */
  letter-spacing: -0.25px;
}

.displayMedium {
  font-family: "IBM Plex Sans";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px; /* 115.556% */
  letter-spacing: 0px;
}

.displaySmall {
  font-family: "IBM Plex Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 122.222% */
  letter-spacing: 0px;
}

.headlineLarge {
  font-family: "IBM Plex Sans";
  font-size: var(--Headline-Large-Size, 32px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Headline-Large-Line-Height, 40px); /* 125% */
  letter-spacing: var(--Headline-Large-Tracking, 0px);
}

.headlineMedium {
  font-family: "IBM Plex Sans";
  font-size: var(--Headline-Medium-Size, 28px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Headline-Medium-Line-Height, 36px); /* 128.571% */
  letter-spacing: var(--Headline-Small-Tracking, 0px);
}

.headlineSmall {
  font-family: "IBM Plex Sans";
  font-size: var(--Headline-Small-Size, 24px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Headline-Small-Line-Height, 32px); /* 133.333% */
  letter-spacing: var(--Headline-Small-Tracking, 0px);
}

.titleLarge {
  font-family: "IBM Plex Sans";
  font-size: var(--Title-Large-Size, 22px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Title-Large-Line-Height, 28px); /* 127.273% */
  letter-spacing: var(--Title-Large-Tracking, 0px);
}

.titleMedium {
  font-family: "IBM Plex Sans";
  font-size: var(--Title-Medium-Size, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Title-Medium-Line-Height, 24px); /* 150% */
  letter-spacing: var(--Title-Medium-Tracking, 0.15px);
}

.titleSmall {
  font-family: "IBM Plex Sans";
  font-size: var(--Title-Small-Size, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Title-Small-Line-Height, 20px); /* 142.857% */
  letter-spacing: var(--Title-Small-Tracking, 0.1px);
}

.labelLarge {
  font-family: "IBM Plex Sans";
  font-size: var(--Label-Large-Size, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Label-Large-Line-Height, 20px); /* 142.857% */
  letter-spacing: var(--Label-Large-Tracking, 0.1px);
}

.labelMedium {
  font-family: "IBM Plex Sans";
  font-size: var(--Label-Medium-Size, 12px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Label-Medium-Line-Height, 16px); /* 133.333% */
  letter-spacing: 0.5px;
}

.labelSmall {
  font-family: "IBM Plex Sans";
  font-size: var(--Label-Small-Size, 11px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Label-Small-Line-Height, 16px); /* 145.455% */
  letter-spacing: var(--Label-Small-Tracking, 0.5px);
}

.bodyLarge {
  font-family: "IBM Plex Sans";
  font-size: var(--Body-Large-Size, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Body-Large-Line-Height, 24px); /* 150% */
  letter-spacing: var(--Body-Large-Tracking, 0.5px);
}

.bodyMedium {
  font-family: "IBM Plex Sans";
  font-size: var(--Body-Medium-Size, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Body-Medium-Line-Height, 20px); /* 142.857% */
  letter-spacing: var(--Body-Medium-Tracking, 0.25px);
}

.bodySmall {
  font-family: "IBM Plex Sans";
  font-size: var(--Body-Small-Size, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Body-Small-Line-Height, 16px); /* 133.333% */
  letter-spacing: var(--Body-Small-Tracking, 0.4px);
}

.buttonLargeBold {
  font-family: "IBM Plex Mono";
  font-size: var(--Title-Medium-Size, 16px);
  font-style: normal;
  font-weight: 700;
  line-height: var(--Title-Medium-Line-Height, 24px); /* 150% */
  letter-spacing: var(--Title-Medium-Tracking, 0.15px);
}

.buttonLarge {
  font-family: "IBM Plex Mono";
  font-size: var(--Title-Medium-Size, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Title-Medium-Line-Height, 24px); /* 150% */
  letter-spacing: var(--Title-Medium-Tracking, 0.15px);
}

.buttonMedium {
  font-family: "IBM Plex Mono";
  font-size: var(--Label-Large-Size, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: var(--Label-Large-Tracking, 0.1px);
}

.buttonSmall {
  font-family: "IBM Plex Mono";
  font-size: var(--Label-Medium-Size, 12px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Label-Medium-Line-Height, 16px); /* 133.333% */
  letter-spacing: 0.5px;
}